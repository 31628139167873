import gql from 'graphql-tag';

export default gql`
  query uoms {
    uoms {
      nameLocal
      name
    }
  }
`;
