import gql from 'graphql-tag';

export default gql`
  query users {
    users {
      data {
        id
        name
        imageUrl
        email
        telephone
        status
      }
    }
  }
`;
