import gql from 'graphql-tag';

export default gql`
  query me {
    me {
      id
      name
      email
      status
      telephone
      imageUrl
      lang
      notifications
    }
  }
`;
