/*
 *
 * MainLayout constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/MainLayout/DEFAULT_ACTION',
  TOGGLE_SIDE_NAV = 'app/MainLayout/TOGGLE_SIDE_NAV',
  SET_LANG = 'app/MainLayout/SET_LANG',
  SIGNOUT = 'app/MainLayout/SIGNOUT',

  CHECK_EMAIL_SUBMIT = 'app/MainLayout/CHECK_EMAIL_SUBMIT',
  CHECK_EMAIL_REQUEST = 'app/MainLayout/CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS = 'app/MainLayout/CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE = 'app/MainLayout/CHECK_EMAIL_FAILURE',

  CATEGORIES_REQUEST = 'app/MainLayout/CATEGORIES_REQUEST',
  CATEGORIES_SUCCESS = 'app/MainLayout/CATEGORIES_SUCCESS',
  CATEGORIES_FAILURE = 'app/MainLayout/CATEGORIES_FAILURE',

  FETCH_USER_REQUEST = 'app/MainLayout/FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'app/MainLayout/FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE = 'app/MainLayout/FETCH_USER_FAILURE',

  FETCH_USER_GROUPS_REQUEST = 'app/MainLayout/FETCH_USER_GROUPS_REQUEST',
  FETCH_USER_GROUPS_SUCCESS = 'app/MainLayout/FETCH_USER_GROUPS_SUCCESS',
  FETCH_USER_GROUPS_FAILURE = 'app/MainLayout/FETCH_USER_GROUPS_FAILURE',
  FETCH_KAMEREO_REGIONS_SUCCESS = 'app/MainLayout/FETCH_KAMEREO_REGIONS_SUCCESS',
}

export default ActionTypes;
