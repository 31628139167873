import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import MainLayout from 'containers/MainLayout';

// tslint:disable-next-line
export default function({ component: Component, ...rest }: any) {
  const loggedIn = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={matchProps =>
        loggedIn ? (
          <MainLayout>
            <Component {...matchProps} />
          </MainLayout>
        ) : (
          <Redirect
            push
            to={{
              pathname: '/signin',
              search: `?redirect=${window.location.pathname}${window.location.search}`,
              state: { referrer: matchProps.location },
            }}
          />
        )
      }
    />
  );
}
