import gql from 'graphql-tag';

export default gql`
  mutation orderDecline($id: ID!, $note: String) {
    orderDecline(id: $id, note: $note) {
      userErrors {
        field
        message
      }
      success
      order {
        id
        total
        status
      }
    }
  }
`;
