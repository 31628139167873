import gql from 'graphql-tag';

export default gql`
  query origins {
    origins {
      nameLocal
      name
    }
  }
`;
