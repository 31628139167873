export const track = (...args) => {
  if (window.analytics) {
    return window.analytics.track(...args);
  }

  console.log('Tracker is not ready.');
};

export const pageView = (...args) => {
  if (window.analytics) {
    return window.analytics.pageView(...args);
  }

  console.log('Tracker is not ready.');
};

export const page = (...args) => {
  if (window.analytics) {
    // console.log('tracking', args);
    return window.analytics.page(...args);
  }

  console.log('Tracker is not ready.');
};
