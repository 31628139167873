import gql from 'graphql-tag';

export default gql`
  mutation clientUpdate($input: ClientUpdateInput) {
    clientUpdate(input: $input) {
      userErrors {
        field
        message
      }
      success
    }
  }
`;
