import gql from 'graphql-tag';

export default gql`
  mutation clientAddAllSellingList($input: ClientAddAllSellingListInput) {
    clientAddAllSellingList(input: $input) {
      userErrors {
        field
        message
      }
      success
    }
  }
`;
