import gql from 'graphql-tag';

export default gql`
  query buyer($id: Int!) {
    buyer(id: $id) {
      id
      name
      telephone
      location {
        city
        district
        address
        ward
      }
      imageUrl
      lastOrder
      redInvoiceInfo {
        companyName
        taxCode
        emailToReceiveEInvoice
        location {
          city
          district
          address
          ward
        }
      }
      paymentMethod
      stores {
        id
        name
        lastOrder(buyerId: $id)
        imageUrl
        telephone
        location {
          city
          district
          address
          ward
        }
        disabled
        status
      }
    }
  }
`;
