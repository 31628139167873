import { call, fork, put, take } from 'redux-saga/effects';
import { checkValidEmail, getCategories, getGroups, getUser, fetchAllKamereoRegions } from 'utils/apollo';
import {
  categories as categoriesActions,
  checkEmail as checkEmailActions,
  groups as groupsActions,
  signout as signoutActions,
  user as userActions,
  setKamereoRegions,
} from './actions';

import { push } from 'connected-react-router';
import ActionTypes from './constants';

function* setLanguageFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_LANG);
    window.localStorage.setItem('lang', payload);
    window.location.reload();
  }
}

function* signoutFlow() {
  while (true) {
    yield take(ActionTypes.SIGNOUT);
    window.localStorage.removeItem('token');
    yield put(push('/signin'));
  }
}

function* checkEmailFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CHECK_EMAIL_SUBMIT);
    yield put(checkEmailActions.request());
    const response = yield call(checkValidEmail, {
      email: payload.email,
    });

    if (!response.errors) {
      yield put(
        checkEmailActions.success({
          key: payload.key,
          message: '',
        }),
      );
    } else {
      const failurePayload = {
        key: payload.key,
        message: response.errors[0].message,
      };
      yield put(checkEmailActions.failure(failurePayload));
    }
  }
}

function* getCategoriesFlow() {
  const response = yield call(getCategories);

  if (!response.errors) {
    yield put(categoriesActions.success(response.categories));
  } else {
    yield put(categoriesActions.failure(response.errors));
  }
}

function* initUserFlow() {
  yield put(userActions.request());
  const response = yield call(getUser);
  if (!response.errors) {
    yield put(userActions.success(response.me));
  } else {
    yield put(userActions.failure(response.errors));
    yield put(signoutActions());
  }
}

function* initGroupsFlow() {
  yield put(groupsActions.request());
  const response = yield call(getGroups);
  if (!response.errors) {
    yield put(groupsActions.success(response.userGroups));
  } else {
    yield put(groupsActions.failure(response.errors));
  }
}

function* getKamereoRegions() {
  const response = yield call(fetchAllKamereoRegions);
  if (!response.errors) {
    yield put(setKamereoRegions(response.getAllKamereoRegion.data));
  }
}

export default function* mainLayoutSaga() {
  yield fork(setLanguageFlow);
  yield fork(signoutFlow);
  yield fork(checkEmailFlow);
  yield fork(getCategoriesFlow);
  yield fork(initUserFlow);
  yield fork(initGroupsFlow);
  yield fork(getKamereoRegions);
}
