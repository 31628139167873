import gql from 'graphql-tag';

export default gql`
  mutation accountCreate($input: AccountCreateInput) {
    accountCreate(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
