import gql from 'graphql-tag';

export default gql`
  query productHistoryDetail($searchRequest: ProductHistoryDetailInput!) {
    productHistoryDetail(searchRequest: $searchRequest) {
      total
      totalPage
      dataList {
        id
        objectId
        objectType
        actioner
        name
        email
        clientId
        actionType
        time
        dateTime
        oldData
        newData
      }
    }
  }
`;
