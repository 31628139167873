import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { PermissionAction } from 'types/schema';
import { initialState } from './reducer';
/**
 * Direct selector to the mainLayout state domain
 */

const selectMainLayoutDomain = (state: ApplicationRootState) => {
  return state ? state.mainLayout : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by MainLayout
 */

const selectMainLayout = () =>
  createSelector(selectMainLayoutDomain, subState => {
    return subState;
  });

const makeSelectOpenSideNav = () =>
  createSelector(selectMainLayoutDomain, subState => {
    return (subState as any).isCollapsedSideNav;
  });

const makeSelectCategories = () =>
  createSelector(selectMainLayoutDomain, subState => {
    return subState.categories;
  });

const makeEmailFormItem = () =>
  createSelector(selectMainLayoutDomain, subState => {
    return (subState as any).emailFormItems;
  });

const selectUser = () =>
  createSelector(selectMainLayoutDomain, subState => {
    return (subState as any).user;
  });

const selectUserGroupsAndPermissions = () => {
  return createSelector(selectMainLayoutDomain, subState => {
    return {
      loading: subState.groups.loading,
      data: subState.groups.data,
      /** Make unique array of Permissions between all groups & filter undefined items */
      permissions: [
        ...new Set(
          subState.groups.data?.flatMap(group => {
            if (!group.permissions) {
              return [];
            }
            return group.permissions.flatMap(permission => permission?.action || []);
          }),
        ),
      ].filter(item => item !== ('' as PermissionAction)),
    };
  });
};

const selectKamereoRegions = () =>
  createSelector(selectMainLayoutDomain, subState => {
    return (subState as any).kamereoRegions;
  });

export default selectMainLayout;
export {
  makeEmailFormItem,
  makeSelectCategories,
  makeSelectOpenSideNav,
  selectMainLayoutDomain,
  selectUser,
  selectUserGroupsAndPermissions,
  selectKamereoRegions,
};
