/*
 * ClientManagementPage Messages
 *
 * This contains all the text for the ClientManagementPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ClientManagementPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Quản lý khách hàng',
  },
  addClient: {
    id: `${scope}.addClient`,
    defaultMessage: 'Thêm khách hàng',
  },
  addClientFail: {
    id: `${scope}.addClientFail`,
    defaultMessage: 'Thêm khách hàng thất bại',
  },
  addClientSuccess: {
    id: `${scope}.addClientSuccess`,
    defaultMessage: 'Thêm khách hàng thành công',
  },
  yourClient: {
    id: `${scope}.yourClient`,
    defaultMessage: 'Khách hàng',
  },
  pendingClient: {
    id: `${scope}.pendingClient`,
    defaultMessage: 'Khách mời',
  },
  invitationLeft: {
    id: `${scope}.invitationLeft`,
    defaultMessage: 'Lời mời còn lại',
  },
});
