import CatalogPage from 'containers/CatalogPage/Loadable';
import ClientManagementPage from 'containers/ClientManagementPage/Loadable';
import CompanyInfoPage from 'containers/CompanyInfoPage/Loadable';
import DashboardPage from 'containers/DashboardPage/Loadable';
import DeliverySettingPage from 'containers/DeliverySettingPage/Loadable';
import { Loadable } from 'loadable-components';
import OrdersPage from 'containers/OrdersPage/Loadable';
import React from 'react';
import UserManagementPage from 'containers/UserManagementPage/Loadable';
import catalogPageMessages from 'containers/CatalogPage/messages';
import cientManagementPageMessages from 'containers/ClientManagementPage/messages';
import companyInfoPageMessages from 'containers/CompanyInfoPage/messages';
import dashboardPageMessages from 'containers/DashboardPage/messages';
import deliverySettingPageMessages from 'containers/DeliverySettingPage/messages';
import messages from './messages';
import ordersPageMessages from 'containers/OrdersPage/messages';
import translations from 'translations';
import userManagementPageMessages from 'containers/UserManagementPage/messages';

interface SubRoute {
  label: string | React.ReactElement<any>;
  path: string;
  component?: React.ReactElement<any> | Loadable<any>;
  exact?: boolean;
}

interface Route {
  label: string | React.ReactElement<any>;
  icon: string | React.ReactElement<any>;
  path?: string;
  component?: React.ReactElement<any> | Loadable<any>;
  subNavs?: SubRoute[];
  exact?: boolean;
}

const routes: Route[] = [
  {
    label: translations(dashboardPageMessages.header),
    icon: 'Overview',
    path: '/',
    component: DashboardPage,
    exact: true,
  },
  {
    label: translations(ordersPageMessages.header),
    icon: 'Orders',
    path: '/orders',
    exact: true,
    component: OrdersPage,
  },
  {
    label: translations(catalogPageMessages.header),
    icon: 'Catalog',
    path: '/catalog',
    exact: true,
    component: CatalogPage,
  },
  {
    label: translations(cientManagementPageMessages.header),
    icon: 'Clients',
    path: '/clients',
    exact: true,
    component: ClientManagementPage,
  },
  {
    label: translations(messages.companySetup),
    icon: 'CompanySetup',
    path: '/company-setup',
    subNavs: [
      {
        label: translations(companyInfoPageMessages.header),
        path: '/company-info',
        component: CompanyInfoPage,
      },
      {
        label: translations(deliverySettingPageMessages.header),
        path: '/delivery-setting',
        component: DeliverySettingPage,
      },
      {
        label: translations(userManagementPageMessages.header),
        path: '/user-management',
        component: UserManagementPage,
      },
    ],
  },
];

export default routes;
