import gql from 'graphql-tag';

export default gql`
  mutation setPaymentMethod($buyerId: Int, $paymentMethod: PaymentMethod) {
    setPaymentMethod(buyerId: $buyerId, paymentMethod: $paymentMethod) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
