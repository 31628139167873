/**
 *
 * SideNav
 *
 */

import { Layout, Menu } from 'antd';
import React, { PureComponent } from 'react';

import Icon from 'components/Icon';
import KamereoLogo from 'components/Imgs/KamereoLogo.png';
import { Link } from 'react-router-dom';
import styles from './styles.scss';

const { Sider } = Layout;

interface OwnProps {
  routes: any[];
  location: any;
  collapsed: boolean;
  isCollaborator: boolean;
}

class SideNav extends PureComponent<OwnProps> {
  state = {
    pathname: '/',
    collapsedWidth: 80,
  };

  static getDerivedStateFromProps(props, state) {
    return {
      pathname: location.pathname,
      collapsedWidth: 80,
    };
  }

  onBreakpoint = (breakpoint: boolean) => {
    if (breakpoint) {
      this.setState({
        collapsedWidth: 0,
      });
    } else {
      this.setState({
        collapsedWidth: 80,
      });
    }
  };

  render() {
    const { routes, collapsed, isCollaborator } = this.props;
    const { pathname } = this.state;
    let route = routes[0];
    if (pathname !== '/') {
      route = routes.find(r => pathname.indexOf(r.path) > -1 && r.path !== '/') || {
        path: pathname,
      };
    }
    const isDisabledSpecificMenuItemForCollaborator = (item: any) => {
      return isCollaborator && !item.path.includes('catalog');
    };
    return (
      <Sider
        className={styles.sider}
        width={213}
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={this.onBreakpoint}
        collapsedWidth={this.state.collapsedWidth}
        breakpoint="md"
      >
        <Link to="/" className={styles.logo}>
          {collapsed ? <h1>K</h1> : <img src={KamereoLogo} style={{ width: '80%' }} />}
        </Link>
        <Menu theme="dark" mode="inline" selectedKeys={[route.path]} defaultSelectedKeys={[route.path]}>
          {routes.map(item =>
            !item.subNavs ? (
              <Menu.Item key={item.path} disabled={isDisabledSpecificMenuItemForCollaborator(item)}>
                <Link to={item.path}>
                  <Icon type={item.icon} style={{ fontSize: '24px' }} />
                  <span>{item.label}</span>
                </Link>
              </Menu.Item>
            ) : (
              <Menu.SubMenu
                popupOffset={[14, 0]}
                key={item.path}
                title={
                  <span>
                    <Icon type={item.icon} style={{ fontSize: '24px' }} />
                    <span>{item.label}</span>
                  </span>
                }
                disabled={isDisabledSpecificMenuItemForCollaborator(item)}
              >
                {item.subNavs.map(subItem => (
                  <Menu.Item key={subItem.path}>
                    <Link to={subItem.path}>{subItem.label}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ),
          )}
        </Menu>
      </Sider>
    );
  }
}

export default SideNav;
