const context = require.context('./', true, /\.(svg)$/);
const files: {
  [key: string]: any;
} = {};

context.keys().forEach((filename: string) => {
  files[filename.replace('./', '').replace('.svg', '')] = context(filename).default;
});

export default files;
