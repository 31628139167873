import gql from 'graphql-tag';

export default gql`
  query userGroups {
    userGroups {
      group {
        id
        name
        createdBy
        activated
        deleted
      }
      permissions {
        action
        scope {
          type
          id
        }
      }
    }
  }
`;
