/*
 * UserGrid Messages
 *
 * This contains all the text for the UserGrid component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.TrackingComponents.Calendar';

export default defineMessages({
  slogan: {
    id: `${scope}.slogan`,
    defaultMessage: 'Hệ thống quản lí đơn hàng cho Nhà Cung Cấp',
  },
});
