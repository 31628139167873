/*
 * TopNav Messages
 *
 * This contains all the text for the TopNav component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.TopNav';

export default defineMessages({
  accountSettings: {
    id: `${scope}.accountSettings`,
    defaultMessage: 'Thông tin tài khoản',
  },
  signout: {
    id: `${scope}.signout`,
    defaultMessage: 'Đăng xuất',
  },
  managementArea: {
    id: `${scope}.managementArea`,
    defaultMessage: 'Khu vực quản lý',
  },
  HCM: {
    id: `${scope}.HCM`,
    defaultMessage: 'Miền Nam',
  },
  HN: {
    id: `${scope}.HN`,
    defaultMessage: 'Miền Bắc',
  },
  ALL: {
    id: `${scope}.ALL`,
    defaultMessage: 'Toàn bộ',
  },
});
