/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';

const selectGlobal = (state: ApplicationRootState) => {
  return state.global;
};

const selectRoute = (state: ApplicationRootState) => {
  return state.router;
};

const makeSelectCurrentUser = () => createSelector(selectGlobal, globalState => globalState.currentUser);

const makeSelectLoading = () => createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () => createSelector(selectGlobal, globalState => globalState.error);

const makeSelectRepos = () => createSelector(selectGlobal, globalState => globalState.userData.repositories);

const makeSelectLocation = () => createSelector(selectRoute, routeState => routeState.location);

const makeSelectRoute = () => createSelector(selectRoute, routeState => routeState);

export {
  selectGlobal,
  makeSelectRoute,
  makeSelectCurrentUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectRepos,
  makeSelectLocation,
};
