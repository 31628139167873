exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".singleSelect__3I_B8{min-width:100pt}@media(min-width: 576px){.singleSelect__3I_B8{min-width:140pt}}.multiSelect__2GxNE .ant-select-selection--multiple .ant-select-selection__choice{background-color:#d3e6f9;color:#3d6a95;border:1px solid #d3e6f9}.multiSelect__2GxNE .ant-select-selection__choice__remove{color:#3d6a95}.pending__26Pq8{box-shadow:#fff58b 0 0 0 197px;background:#fff58b;color:#5d5500;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}.accepted__oVO-M{box-shadow:#d3e6f9 0 0 0 197px;background:#d3e6f9;color:#3d6a95;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}.canceled__9PTUl{box-shadow:#eef0f0 0 0 0 197px;background:#eef0f0;color:#475a5f;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}.declined__3UwRP{box-shadow:#fed3d0 0 0 0 197px;background:#fed3d0;color:#a23d37;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}.sent__U-iRI{box-shadow:#d3e6f9 0 0 0 197px;background:#d3e6f9;color:#3d6a95;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}.delivered__3hQ77{box-shadow:#d0e6d7 0 0 0 197px;background:#d0e6d7;color:#366946;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}.textDefault__bWif0{box-shadow:#d3e6f9 0 0 0 197px;background:#d3e6f9;color:#3d6a95;padding-right:25px;padding-left:10px;border:none;text-transform:capitalize}", ""]);

// exports
exports.locals = {
	"singleSelect": "singleSelect__3I_B8",
	"multiSelect": "multiSelect__2GxNE",
	"pending": "pending__26Pq8",
	"accepted": "accepted__oVO-M",
	"canceled": "canceled__9PTUl",
	"declined": "declined__3UwRP",
	"sent": "sent__U-iRI",
	"delivered": "delivered__3hQ77",
	"textDefault": "textDefault__bWif0"
};