import { FormattedMessage } from 'react-intl';
import KamereoLogo from '../Imgs/KamereoLogo.svg';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import Select from 'components/Select';
import messages from './messages';
import styles from './styles.scss';

interface GateLayoutProps extends RouteProps {
  children?: JSX.Element[] | JSX.Element;
  image: string;
}

export default ({ children, image }: GateLayoutProps) => {
  const onChangeLang = value => {
    window.localStorage.setItem('lang', value);
    window.location.reload();
  };
  const lang = window.localStorage.getItem('lang') || 'vi';

  return (
    <div className={styles.fluidContainer}>
      <div className={styles.rightContainer}>
        <div className={styles.background} style={{ backgroundImage: `url(${image})` }}>
          <div className={styles.mask}>
            <p>
              <FormattedMessage {...messages.slogan} />
            </p>
          </div>
        </div>
      </div>
      <div className={styles.leftContainer}>
        <div className={styles.content}>
          <div className={styles.topContent}>
            <div>
              <img src={KamereoLogo} />
            </div>
            <div>
              <Select
                onChange={onChangeLang}
                size="small"
                value={lang}
                items={[
                  { value: 'en', label: 'EN' },
                  { value: 'vi', label: 'VI' },
                ]}
                style={{ minWidth: 60 }}
              />
            </div>
          </div>
          <div className={styles.mainContent}>{children}</div>
        </div>
      </div>
    </div>
  );
};
