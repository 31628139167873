exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fluidContainer__31-sm{background-color:#fff;width:100%;height:100%;display:flex;flex-direction:column}@media(min-width: 992px){.fluidContainer__31-sm{flex-direction:row}}.rightContainer__2tmN-{flex:1 0 50%}.background__1_i5o{width:100%;height:100%;background-position:center;background-size:cover}.mask__9L4KA{width:100%;height:100%;display:flex;align-items:center}.mask__9L4KA p{color:#fff;font-size:36px;line-height:44px;padding:42px;text-shadow:0px 4px 4px rgba(0,0,0,.25)}.leftContainer__2GqTh{flex:1 0 50%;background-color:#fff}.content__2wccQ{width:400px;height:calc(100% - 180px);margin:90px auto;display:flex;flex-direction:column}.topContent__18Ji-{flex:0 0 0;margin-bottom:30px;display:flex;align-items:center;justify-content:space-between}.mainContent__2L9xP{flex:1 0 0;display:flex;align-items:center}.mainContent__2L9xP>div{flex:1 0 0}", ""]);

// exports
exports.locals = {
	"fluidContainer": "fluidContainer__31-sm",
	"rightContainer": "rightContainer__2tmN-",
	"background": "background__1_i5o",
	"mask": "mask__9L4KA",
	"leftContainer": "leftContainer__2GqTh",
	"content": "content__2wccQ",
	"topContent": "topContent__18Ji-",
	"mainContent": "mainContent__2L9xP"
};