import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MainLayout';

export default defineMessages({
  companySetup: {
    id: `${scope}.companySetup`,
    defaultMessage: 'Cài đặt chung',
  },
  introduceFormLabel: {
    id: `${scope}.introduceFormLabel`,
    defaultMessage: 'Bạn đã nhận được 20 lời mời miễn phí để thử!',
  },
  introduceFormContent: {
    id: `${scope}.introduceFormContent`,
    defaultMessage: 'Bây giờ bạn có thể mời lên đến 20 khách hàng để bắt đầu đặt hàng trên Kamereo, miễn phí.',
  },
  introduceFormTryNow: {
    id: `${scope}.introduceFormTryNow`,
    defaultMessage: 'Nhấp vào Hãy dùng thử ngay bây giờ để bắt đầu sử dụng tính năng này.',
  },
});
