import gql from 'graphql-tag';

export default gql`
  mutation setBuyerSettings($buyerId: Int!, $input: BuyerSettingsInput!) {
    setBuyerSettings(buyerId: $buyerId, input: $input) {
      success
      userErrors {
        field
        message
      }
      settings {
        id
        moq
        deliverySchedule {
          weekday
          start
          end
          timeWindow
        }
        fulfillmentTime {
          hoursToDelivery
          days
          cutoffTime
        }
      }
    }
  }
`;
