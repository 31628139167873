import gql from 'graphql-tag';

export default gql`
  query supplier {
    supplier {
      id
      name
      description
      descriptionVn
      imageUrl
      location {
        city
        district
        address
      }
      telephone
    }
  }
`;
