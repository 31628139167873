import gql from 'graphql-tag';

export default gql`
  mutation orderImport($input: ImportOrderInput!) {
    orderImport(input: $input) {
      success
      userErrors {
        field
        message
      }
      orderId
      errorMessage
    }
  }
`;
