/*
 *
 * MainLayout reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import { combineReducers } from 'redux';
import { SupplierUserGroupWithPermissions, RegionItem } from 'types/schema';
import ActionTypes from './constants';

export const initialState: ContainerState = {
  categories: [],
  isCollapsedSideNav: false,
  lang: window.localStorage.getItem('lang') || 'vi',
  emailFormItems: {},
  user: {
    loading: false,
  },
  groups: { loading: false },
  kamereoRegions: [],
};

function sideNavReducer(state = initialState.isCollapsedSideNav, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.TOGGLE_SIDE_NAV:
      return !state;
    default:
      return state;
  }
}

function emailFormItemReducer(state = initialState.emailFormItems, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.CHECK_EMAIL_SUBMIT:
      const submitEmailId = action.payload.key;
      return {
        ...state,
        [submitEmailId]: {
          isCheckingEmail: true,
          validationEmailMessage: '',
        },
      };
    case ActionTypes.CHECK_EMAIL_FAILURE: {
      const receiveEmailId = action.payload.key;
      return {
        ...state,
        [receiveEmailId]: {
          isCheckingEmail: false,
          validationEmailMessage: action.payload.message,
        },
      };
    }
    case ActionTypes.CHECK_EMAIL_SUCCESS: {
      const receiveEmailId = action.payload.key;
      return {
        ...state,
        [receiveEmailId]: {
          isCheckingEmail: false,
          validationEmailMessage: action.payload.message,
        },
      };
    }
    default:
      return state;
  }
}

function categoriesReducer(state = initialState.categories, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.CATEGORIES_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}

function userReducer(state = initialState.user, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    default:
      return state;
  }
}

function groupsReducer(
  state = initialState.groups,
  action: ContainerActions & { payload: SupplierUserGroupWithPermissions[] },
) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_USER_GROUPS_REQUEST:
    case ActionTypes.FETCH_USER_GROUPS_FAILURE:
      return { ...state, loading: true };
    case ActionTypes.FETCH_USER_GROUPS_SUCCESS:
      return { loading: false, data: payload };
    default:
      return state;
  }
}

function kamereoRegionsReducer(
  state = initialState.kamereoRegions,
  action: ContainerActions & { payload: RegionItem[] },
) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_KAMEREO_REGIONS_SUCCESS:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  isCollapsedSideNav: sideNavReducer,
  emailFormItems: emailFormItemReducer,
  categories: categoriesReducer,
  user: userReducer,
  groups: groupsReducer,
  kamereoRegions: kamereoRegionsReducer,
});
