import gql from 'graphql-tag';

export default gql`
  query tags {
    tags {
      name
      id
    }
  }
`;
