/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Redirect, Router, Switch, withRouter } from 'react-router-dom';

import ForgotPasswordImg from 'components/Imgs/ForgotPassword.jpg';
import ResetPasswordImg from 'components/Imgs/ResetPassword.jpg';
import SigninPageImg from 'components/Imgs/SigninPage.jpg';
import SignupPageImg from 'components/Imgs/SignupPage.jpg';
import AccountPage from 'containers/AccountPage/Loadable';
import ClientDetailsPage from 'containers/ClientDetailsPage/Loadable';
import CreateItemPage from 'containers/CreateItemPage/Loadable';
import ForgotPage from 'containers/ForgotPage/Loadable';
import ItemSetupPage from 'containers/ItemSetupPage/Loadable';
import mainRoutes from 'containers/MainLayout/routes';
import OrderDetailsPage from 'containers/OrderDetailsPage/Loadable';
import PricingPage from 'containers/PricingPage/Loadable';
import ResetPage from 'containers/ResetPage/Loadable';
import SigninPage from 'containers/SigninPage/Loadable';
import SignupPage from 'containers/SignupPage/Loadable';
import history from 'utils/history';
import { page } from 'utils/track';
import GateRoute from './GateRoute';
import MainRoute from './MainRoute';

const PageManager = withRouter((props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    page(document.title);
  }, [props.location.pathname, props.location.search]);

  return props.children;
});

const routes = mainRoutes.reduce((prev, curr) => {
  if (curr.subNavs) {
    return prev.concat(curr.subNavs);
  }
  return prev.concat(curr);
}, [] as any);

const App: React.SFC<{}> = () => {
  const originalUrl = `${window.location.pathname}${window.location.search}`;
  return (
    <Router history={history}>
      <PageManager>
        <Switch>
          {routes.map(route => (
            <MainRoute {...route} />
          ))}
          <MainRoute path="/orders/:slug" component={OrderDetailsPage} />
          <MainRoute path="/clients/:slug" component={ClientDetailsPage} />
          <MainRoute path="/catalog/:slug" component={CreateItemPage} />
          <MainRoute path="/pricing" component={PricingPage} />
          <MainRoute path="/account-info" component={AccountPage} />
          <MainRoute path="/item-setup" component={ItemSetupPage} />
          <GateRoute path="/signin" component={SigninPage} image={SigninPageImg} />
          <GateRoute path="/operation-team-signup" component={SignupPage} image={SignupPageImg} />
          <GateRoute path="/forgot-password" component={ForgotPage} image={ForgotPasswordImg} />
          <GateRoute path="/reset-password" component={ResetPage} image={ResetPasswordImg} />
          {originalUrl.includes('/v2') && <Redirect to={originalUrl.replace('/v2', '')} />}
          <Redirect to="/" />
        </Switch>
      </PageManager>
    </Router>
  );
};

export default App;
