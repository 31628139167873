import gql from 'graphql-tag';

export default gql`
  query categories {
    categories {
      nameLocal
      name
      id
      parent {
        nameLocal
        name
        id
      }
    }
  }
`;
