/*
 * DeliverySettingPage Messages
 *
 * This contains all the text for the DeliverySettingPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DeliverySettingPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Thiết lập giao hàng',
  },
});
