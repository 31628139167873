/**
 *
 * TopNav
 *
 */

import { Dropdown, Icon, Layout, Menu } from 'antd';
import React, { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import messages from './messages';
import styles from './styles.scss';
import { AREA } from 'types/appSchema';
import translations from 'translations';
import { RegionItem } from 'types/schema';

const { Header } = Layout;

interface OwnProps extends RouteComponentProps {
  toggleSideNav: () => void;
  onSetLang: (data: any) => void | undefined;
  collapsed: boolean;
  user: any;
  signout: () => void;
  kamereoRegions: RegionItem[];
}

const TopNav: FC<OwnProps> = props => {
  const { collapsed, signout, user, onSetLang, toggleSideNav, kamereoRegions, location } = props;
  const lang = window.localStorage.getItem('lang') || 'vi';
  const displayedLang = lang === 'en' ? 'vi' : 'en';

  const onChangeManagementArea = value => {
    if (value.key !== window.localStorage.getItem('managementArea')) {
      window.localStorage.setItem('managementArea', value.key);
      window.location.reload();
    }
  };

  const isAllArea = useMemo(() => {
    return (
      ['/company-info', '/delivery-setting', '/user-management', '/catalog'].includes(location.pathname) ||
      /^\/catalog\/[a-zA-Z0-9-_]+$/.test(location.pathname)
    );
  }, [location]);

  const areaValue = useMemo(() => {
    const areaValue = window.localStorage.getItem('managementArea') || AREA.SOUTHERN;
    const regionCodes = Object.values(AREA).filter(v => isNaN(Number(v)));
    if (
      !window.localStorage.getItem('managementArea') ||
      (regionCodes.length && !regionCodes.includes(areaValue as AREA))
    ) {
      window.localStorage.setItem('managementArea', AREA.SOUTHERN);
    }
    if (isAllArea) {
      return {
        text: translations(messages.ALL),
        value: 'ALL',
      };
    }
    return {
      text: translations(messages[areaValue] ? messages[areaValue] : messages[AREA.SOUTHERN]),
      value: areaValue,
    };
  }, [isAllArea]);

  const menu = useMemo(() => {
    return (
      <Menu onClick={onChangeManagementArea}>
        {kamereoRegions.map(item => (
          <Menu.Item
            key={item.code}
            style={{ background: item.code === window.localStorage.getItem('managementArea') ? '#d3e6f9c2' : '' }}
          >
            {item.code ? translations(messages[item.code]) : ''}
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [kamereoRegions]);

  const onChangeLang = (lang: 'vi' | 'en') => () => onSetLang(lang);

  return (
    <Header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={toggleSideNav}>
            <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} />
          </button>
          {process.env.APP_ENV === 'production' ? false : <div className={styles.qa}>QA</div>}
        </div>
        <div className={styles.rightContent}>
          <div className={styles.rightContentWrapper}>
            <Dropdown overlay={menu} trigger={['click']} className={styles.areaDropdown} disabled={isAllArea}>
              <a onClick={e => e.preventDefault()}>
                <FormattedMessage {...messages.managementArea} />:{' '}
                <span className={styles.areaValue}>{areaValue.text}</span> <Icon type="down" />
              </a>
            </Dropdown>
            <Menu
              mode="horizontal"
              theme="light"
              className={styles.menu}
              overflowedIndicator={<Icon className={styles.noMargin} type="user" />}
            >
              {/* <Menu.Item style={{ backgroundColor: 'blue' }} className={styles.menuItem} key="env">
                QA
              </Menu.Item> */}
              <Menu.Item className={styles.menuItem} key="lang" onClick={onChangeLang(displayedLang)}>
                {displayedLang.toUpperCase()}
              </Menu.Item>
              <Menu.SubMenu
                className={styles.menuItem}
                title={
                  <span className="submenu-title-wrapper">
                    <img width={32} height={32} className={styles.avatar} src={user.imageUrl} alt="avatar" />
                  </span>
                }
              >
                <Menu.Item key="account">
                  <Link to="/account-info">
                    <FormattedMessage {...messages.accountSettings} />
                  </Link>
                </Menu.Item>
                <Menu.Item key="signout" onClick={signout}>
                  <FormattedMessage {...messages.signout} />
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default withRouter(TopNav);
