import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import GateLayout from 'components/GateLayout';

export default ({ component: Component, image, ...rest }: any) => {
  const loggedIn = !!localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={matchProps =>
        loggedIn ? (
          <Redirect
            push
            to={{
              pathname: '/',
              state: { referrer: matchProps.location },
            }}
          />
        ) : (
          <GateLayout image={image}>
            <Component {...matchProps} />
          </GateLayout>
        )
      }
    />
  );
};
