/*
 *
 * MainLayout actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const toggleSideNav = () => action(ActionTypes.TOGGLE_SIDE_NAV);
export const setLang = (data: any) => action(ActionTypes.SET_LANG, data);
export const signout = () => action(ActionTypes.SIGNOUT);

export const categories = createAsyncAction(
  ActionTypes.CATEGORIES_REQUEST,
  ActionTypes.CATEGORIES_SUCCESS,
  ActionTypes.CATEGORIES_FAILURE,
)<void, {}, {}>();

// export const checkEmailSubmit = (values: any) => action(ActionTypes.CHECK_EMAIL_SUBMIT, values);
export const checkEmailSubmit = (values: any) => ({
  type: ActionTypes.CHECK_EMAIL_SUBMIT,
  payload: values,
});

export const checkEmail = createAsyncAction(
  ActionTypes.CHECK_EMAIL_REQUEST,
  ActionTypes.CHECK_EMAIL_SUCCESS,
  ActionTypes.CHECK_EMAIL_FAILURE,
)<void, {}, {}>();

export const user = createAsyncAction(
  ActionTypes.FETCH_USER_REQUEST,
  ActionTypes.FETCH_USER_SUCCESS,
  ActionTypes.FETCH_USER_FAILURE,
)<void, {}, Error>();

export const groups = createAsyncAction(
  ActionTypes.FETCH_USER_GROUPS_REQUEST,
  ActionTypes.FETCH_USER_GROUPS_SUCCESS,
  ActionTypes.FETCH_USER_GROUPS_FAILURE,
)<void, {}, Error>();

export const setKamereoRegions = (data: any) => action(ActionTypes.FETCH_KAMEREO_REGIONS_SUCCESS, data);
