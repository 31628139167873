import gql from 'graphql-tag';

export default gql`
  query products($sort: [ProductSort], $filter: ProductFilterInput, $pagination: Pagination) {
    products(sort: $sort, filter: $filter, pagination: $pagination) {
      total
      totalPage
      data {
        id
        intId
        name
        nameVn
        nameLocal
        description
        descriptionVn
        imageUrl
        origin
        preservation
        unitPrice {
          vat
          value
          vatIncluded
        }
        uom
        uomLocal
        category
        categoryLocal
        disabled
        createdAt
        updatedAt
        offMarket
        private
        internalCode
        productInfoByRegion {
          itemId
          regionCode
          price
          inStock
          exclusive
        }
      }
    }
  }
`;
