import { Select as ASelect } from 'antd';
import { SelectProps as ASelectProps } from 'antd/lib/select';
import Icon from 'components/Icon';
import React, { FC } from 'react';
import styles from './styles.scss';

export interface SelectItem {
  value: any;
  label: string;
  color?: string;
}

export interface SelectProps extends ASelectProps {
  className?: string;
  items: SelectItem[];
}

const Select: FC<SelectProps> = props => {
  const { className = '', mode, items, ...rest } = props;
  const ownClassName = mode === 'multiple' || mode === 'tags' ? styles.multiSelect : styles.singleSelect;

  return (
    <ASelect
      {...rest}
      mode={mode}
      className={`${ownClassName} ${className}`}
      suffixIcon={<Icon className={styles.arrowDown} type="ArrowDown" />}
    >
      {items.map(item => (
        <ASelect.Option className={styles.selectOption} key={item.label} value={item.value}>
          {item.label}
        </ASelect.Option>
      ))}
    </ASelect>
  );
};

export default Select;
