import gql from 'graphql-tag';

export default gql`
  mutation clientDeleteSellingItem($input: ClientDeleteSellingItemInput) {
    clientDeleteSellingItem(input: $input) {
      userErrors {
        field
        message
      }
      success
    }
  }
`;
