import gql from 'graphql-tag';

export default gql`
  mutation productSetStock($productId: ID!, $disabled: Boolean!, $productInfoByRegion: [ProductInfoByRegionInput]!) {
    productSetStock(productId: $productId, disabled: $disabled, productInfoByRegion: $productInfoByRegion) {
      success
      userErrors {
        field
        message
      }
      product {
        id
        disabled
      }
    }
  }
`;
