import gql from 'graphql-tag';

export default gql`
  query productDiscountPriceBuyers($id: ID!) {
    productDiscountPriceBuyers(id: $id) {
      price
      vatIncluded
      vat
      buyer {
        id
        name
      }
    }
  }
`;
