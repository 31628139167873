/*
 * DashboardPage Messages
 *
 * This contains all the text for the DashboardPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DashboardPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Tổng quan',
  },
  pendingOrders: {
    id: `${scope}.pendingOrders`,
    defaultMessage: 'Đơn hàng đang chờ duyệt',
  },
  activityReport: {
    id: `${scope}.activityReport`,
    defaultMessage: 'Báo cáo hoạt động',
  },
  deliveryToday: {
    id: `${scope}.deliveryToday`,
    defaultMessage: 'Giao hàng hôm nay',
  },
  ordersToday: {
    id: `${scope}.ordersToday`,
    defaultMessage: 'Đơn hàng hôm nay',
  },
  totalSales: {
    id: `${scope}.totalSales`,
    defaultMessage: 'Tổng: ',
  },
});
