import gql from 'graphql-tag';

export default gql`
  mutation productCreate($input: ProductInput!) {
    productCreate(input: $input) {
      success
      userErrors {
        field
        message
      }
      product {
        id
      }
    }
  }
`;
