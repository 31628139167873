import ActionTypes from './constants';
import { ContainerActions, ContainerState } from './types';

// The initial state of the App
export const initialState: ContainerState = {
  authenticating: false,
  loading: false,
  error: false,
  currentUser: '',
  userData: {
    repositories: [],
  },
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function appReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.AUTHENTICATING:
      return {
        ...state,
        authenticating: payload,
      };
    default:
      return state;
  }
}

export default appReducer;
