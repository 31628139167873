import gql from 'graphql-tag';

export default gql`
  query products($sort: [ProductSort], $filter: ProductFilterInput, $pagination: Pagination, $buyerId: Int) {
    products(sort: $sort, filter: $filter, pagination: $pagination) {
      total
      totalPage
      data {
        id
        name
        nameVn
        nameLocal
        imageUrl
        unitPrice {
          vat
          value
          vatIncluded
        }
        specialUnitPrice(buyerId: $buyerId) {
          vat
          value
          vatIncluded
        }
        uom
        uomLocal
        private
        internalCode
      }
    }
  }
`;
