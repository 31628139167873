import gql from 'graphql-tag';

export default gql`
  query preservations {
    preservations {
      nameLocal
      name
    }
  }
`;
