import gql from 'graphql-tag';

export default gql`
  query settings {
    settings {
      id
      moq
      deliverySchedule {
        weekday
        start
        end
        timeWindow
      }
      deliveryDayOffs {
        name
        start
        end
      }
      fulfillmentTime {
        hoursToDelivery
        days
        cutoffTime
      }
    }
  }
`;
