/*
 * CompanyInfoPage Messages
 *
 * This contains all the text for the CompanyInfoPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CompanyInfoPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Thông tin công ty',
  },
  viewAsBuyer: {
    id: `${scope}.viewAsBuyer`,
    defaultMessage: 'Xem tài khoản của bạn từ giao diện của Nhà Hàng',
  },
  viewAsBuyerHeader: {
    id: `${scope}.viewAsBuyerHeader`,
    defaultMessage: 'Xem trang giới thiệu',
  },
  unregisteredSupplier: {
    id: `${scope}.unregisteredSupplier`,
    defaultMessage: 'Nhà cung cấp chưa đăng ký',
  },
  registeredSupplier: {
    id: `${scope}.registeredSupplier`,
    defaultMessage: 'Nhà cung cấp đã đăng ký',
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: 'Xem chi tiết',
  },
  deliveryInfo: {
    id: `${scope}.deliveryInfo`,
    defaultMessage: 'Chi tiết giao hàng',
  },
  orderDeadline: {
    id: `${scope}.orderDeadline`,
    defaultMessage: 'Hạn Chót Đặt Hàng',
  },
  PIC: {
    id: `${scope}.PIC`,
    defaultMessage: 'Nguời phụ trách',
  },
  contactInfo: {
    id: `${scope}.contactInfo`,
    defaultMessage: 'Thông tin liên lạc',
  },
  Mobile: {
    id: `${scope}.Mobile`,
    defaultMessage: 'Số Điện Thoại',
  },
  deliveryTime: {
    id: `${scope}.deliveryTime`,
    defaultMessage: 'Thời Gian Giao Hàng',
  },
  moq: {
    id: `${scope}.moq`,
    defaultMessage: 'Đơn hàng tối thiểu',
  },
  productCount: {
    id: `${scope}.productCount`,
    defaultMessage: 'Số lượng sản phẩm',
  },
});
