import gql from 'graphql-tag';

export default gql`
  mutation companyUpdateRedInvoice($buyerId: Int!, $input: CompanyUpdateRedInvoiceInput!) {
    companyUpdateRedInvoice(buyerId: $buyerId, input: $input) {
      userErrors {
        field
        message
      }
      success
    }
  }
`;
