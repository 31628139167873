import gql from 'graphql-tag';

export default gql`
  mutation accountUpdate($input: AccountUpdateInput!) {
    accountUpdate(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
