exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sider__3zILf{background:linear-gradient(0deg, #54a46e, #54a46e),#5569c2}.sider__3zILf .ant-menu-item.ant-menu-item{height:60px;line-height:60px;margin:0}.sider__3zILf .ant-menu-inline .ant-menu-item:not(:last-child){margin:0}.sider__3zILf .ant-menu-dark,.sider__3zILf .ant-menu-dark .ant-menu-inline.ant-menu-sub{box-shadow:none}.sider__3zILf .ant-menu .ant-menu-submenu-open{color:#fff}.sider__3zILf .ant-menu-inline-collapsed > .ant-menu-item{padding:0 16px !important;text-align:center}.sider__3zILf .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-title{text-align:center}.sider__3zILf .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{height:60px;line-height:60px;margin:0}.sider__3zILf .ant-menu.ant-menu-dark .ant-menu-item-selected,.sider__3zILf .ant-menu-submenu-popup.ant-menu-dark.ant-menu-item-selected{background:rgba(255,255,255,.3)}.sider__3zILf .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{padding:0 16px !important;text-align:center}.sider__3zILf .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-title{text-align:center}.logo__3RtIg{font-family:Montserrat,serif;display:flex;justify-content:center;align-items:center;height:60px}.logo__3RtIg h1{color:#fff;font-weight:bold;font-size:24px;margin-bottom:0}", ""]);

// exports
exports.locals = {
	"sider": "sider__3zILf",
	"logo": "logo__3RtIg"
};