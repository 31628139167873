import gql from 'graphql-tag';

export default gql`
  mutation invitationUpdatePaymentMethod($input: PaymentMathodInput) {
    invitationUpdatePaymentMethod(input: $input) {
      userErrors {
        field
        message
      }
      success
    }
  }
`;
