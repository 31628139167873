import gql from 'graphql-tag';

export default gql`
  mutation setSettings($input: SettingsInput) {
    setSettings(input: $input) {
      success
      userErrors {
        field
        message
      }
      settings {
        id
        moq
        deliverySchedule {
          weekday
          start
          end
          timeWindow
        }
        deliveryDayOffs {
          name
          start
          end
        }
        fulfillmentTime {
          hoursToDelivery
          days
          cutoffTime
        }
      }
    }
  }
`;
