import gql from 'graphql-tag';

export default gql`
  query orderActivity($dateTime: DatetimeRange!, $orderStatuses: [OrderStatus!], $regionCodes: [String]) {
    orderActivity(dateTime: $dateTime, orderStatuses: $orderStatuses, regionCodes: $regionCodes) {
      success
      userErrors {
        field
        message
      }
      orderActivity {
        time
        total
      }
    }
  }
`;
