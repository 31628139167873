import gql from 'graphql-tag';

export default gql`
  query clientSellingList($input: ClientSellingListInput, $buyerId: Int) {
    clientSellingList(input: $input) {
      total
      data {
        id
        name
        nameVn
        nameLocal
        imageUrl
        vat
        uom
        uomLocal
        unitPrice {
          vat
          value
          vatIncluded
        }
        specialUnitPrice(buyerId: $buyerId) {
          vat
          value
          vatIncluded
        }
        private
        category
      }
    }
  }
`;
