import gql from 'graphql-tag';

export default gql`
  query buyerSettings($buyerId: Int!) {
    buyerSettings(buyerId: $buyerId) {
      id
      moq
      deliverySchedule {
        weekday
        start
        end
        timeWindow
      }
      fulfillmentTime {
        hoursToDelivery
        days
        cutoffTime
      }
      peopleInCharge {
        id
      }
    }
  }
`;
