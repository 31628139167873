import gql from 'graphql-tag';

export default gql`
  mutation clientDownloadSellingPDF($input: DownloadSellingPDFInput) {
    clientDownloadSellingPDF(input: $input) {
      userErrors {
        field
        message
      }
      success
      url
    }
  }
`;
