exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".layout__q-nJe{height:100%}.content__1K7tG{padding:16px}@media(min-width: 768px){.content__1K7tG{padding:25px 32px 32px 32px}}", ""]);

// exports
exports.locals = {
	"layout": "layout__q-nJe",
	"content": "content__1K7tG"
};