exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header__3kuqI{background:#fff;padding:0;border-bottom:#e8ebf2 1px solid}.container__3Lwna{display:flex;flex-direction:row}.avatar__2Ulbd{border-radius:50%}.trigger__1gHJo{font-size:18px;line-height:60px;padding:0 24px;cursor:pointer;transition:color .3s}.trigger__1gHJo:hover{color:#1890ff}.qa__x5d6C{background:#fe5043;padding-left:24px;padding-right:24px;color:#fff;height:63px}.menu__TFZCz{line-height:64px;background:rgba(0,0,0,0);border-bottom:none;float:right;display:flex;justify-content:flex-end;margin-right:8px}.menuItem__1GbCG{border-left:1px solid #e8ebf2}.noMargin__1Qlhv{margin:0 !important}.leftContent__1j4Ob{flex-direction:row;display:flex}.leftContent__1j4Ob button{display:flex}.rightContent__WFZ2q{flex:1}.rightContentWrapper__281kt{width:fit-content;margin-left:auto}.areaDropdown__qIkI5{margin-right:20px;border:1px solid #43a047;border-radius:24px;padding:10px 8px;color:#808289}.areaDropdown__qIkI5:hover{color:#43a047}.areaDropdown__qIkI5:hover .areaValue__1Y9pA{color:#43a047}.areaDropdown__qIkI5.ant-dropdown-open__3eb51{color:#43a047}.areaDropdown__qIkI5.ant-dropdown-open__3eb51 .areaValue__1Y9pA{color:#43a047}.areaValue__1Y9pA{color:#242527;font-size:14px;font-weight:500}", ""]);

// exports
exports.locals = {
	"header": "header__3kuqI",
	"container": "container__3Lwna",
	"avatar": "avatar__2Ulbd",
	"trigger": "trigger__1gHJo",
	"qa": "qa__x5d6C",
	"menu": "menu__TFZCz",
	"menuItem": "menuItem__1GbCG",
	"noMargin": "noMargin__1Qlhv",
	"leftContent": "leftContent__1j4Ob",
	"rightContent": "rightContent__WFZ2q",
	"rightContentWrapper": "rightContentWrapper__281kt",
	"areaDropdown": "areaDropdown__qIkI5",
	"areaValue": "areaValue__1Y9pA",
	"ant-dropdown-open": "ant-dropdown-open__3eb51"
};