import gql from 'graphql-tag';

export default gql`
  mutation clientDelete($input: ClientDeleteInput) {
    clientDelete(input: $input) {
      userErrors {
        field
        message
      }
      success
    }
  }
`;
