import * as _ from 'lodash';

import en from './en';
import vi from './vi';

export default function (message, data) {
  const lang = window.localStorage.getItem('lang') || 'vi';
  let result = vi[message.id] || message.defaultMessage;
  if (lang === 'en') {
    result = en[message.id] || message.defaultMessage;
  }

  if (data) {
    const compiled = _.template(result, {
      interpolate: /{([\s\S]+?)}/g,
    });
    return compiled(data);
  }

  return result;
}
