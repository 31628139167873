import gql from 'graphql-tag';

export default gql`
  query orderStatistic($dateTime: DatetimeRange!, $regionCodes: [String]) {
    orderStatistic(dateTime: $dateTime, regionCodes: $regionCodes) {
      success
      userErrors {
        field
        message
      }
      data {
        orderedWithinPeriod
        deliverWithinPeriod
        pending
      }
    }
  }
`;
