import gql from 'graphql-tag';

export default gql`
  query getAllKamereoRegion {
    getAllKamereoRegion {
      data {
        code
        name
        nameVn
      }
    }
  }
`;
