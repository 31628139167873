import gql from 'graphql-tag';

export default gql`
  mutation companyUpdate($input: CompanyUpdateInput) {
    companyUpdate(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
