/**
 *
 * MainLayout
 *
 */

import React, { PureComponent } from 'react';
import { Dispatch, compose } from 'redux';
import { setLang, signout, toggleSideNav } from './actions';

import { Layout } from 'antd';
import SideNav from 'components/SideNav';
import TopNav from 'components/TopNav';
import { makeSelectLocation } from 'containers/App/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import reducer from './reducer';
import routes from './routes';
import saga from './saga';
import selectMainLayout, { selectKamereoRegions } from './selectors';
import styles from './styles.scss';
import { RootState } from './types';
import { RegionItem, PermissionAction, SupplierUserGroupWithPermissions } from 'types/schema';
import { LoadingState } from 'types';
import { withRouter, RouteComponentProps, RouteProps } from 'react-router-dom';

// import IntroduceForm from './IntroduceForm';
import { selectUserGroupsAndPermissions } from 'containers/MainLayout/selectors';

const { Content } = Layout;

interface OwnProps {}

interface StateProps {
  mainlayout: any;
  location: any;
  kamereoRegions: RegionItem[];
  userGroupsAndPermissions: LoadingState<SupplierUserGroupWithPermissions[]> & { permissions: PermissionAction[] };
}

interface DispatchProps {
  ontoggleSideNav: () => void;
  setLang: (data: any) => void;
  signout: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & RouteProps;
export class MainLayout extends PureComponent<Props> {
  render() {
    const { location, userGroupsAndPermissions, history, kamereoRegions } = this.props;
    const { isCollapsedSideNav, user } = this.props.mainlayout;
    if (window.clarity) {
      window.clarity('set', 'user_email', user?.email);
      window.clarity('identify', user?.id);
    }
    let isCollaborator = false;
    if (userGroupsAndPermissions?.permissions) {
      if (
        userGroupsAndPermissions?.permissions?.length &&
        userGroupsAndPermissions?.permissions?.length === 1 &&
        userGroupsAndPermissions?.permissions?.includes(PermissionAction.SupplierEditItemDescription)
      ) {
        isCollaborator = true;
      }
    }
    if (isCollaborator && !location.pathname.includes('catalog')) {
      history.push('/catalog');
    }
    return (
      <Layout className={styles.layout}>
        <SideNav isCollaborator={isCollaborator} location={location} routes={routes} collapsed={isCollapsedSideNav} />
        <Layout>
          <TopNav
            user={user}
            toggleSideNav={this.props.ontoggleSideNav}
            collapsed={isCollapsedSideNav}
            onSetLang={this.props.setLang}
            signout={this.props.signout}
            kamereoRegions={kamereoRegions || []}
          />
          <Content className={styles.content}>{this.props.children}</Content>
        </Layout>
        {/* <IntroduceForm /> */}
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  mainlayout: selectMainLayout(),
  location: makeSelectLocation(),
  kamereoRegions: selectKamereoRegions(),
  userGroupsAndPermissions: selectUserGroupsAndPermissions(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    ontoggleSideNav: () => dispatch(toggleSideNav()),
    setLang: (data: any) => dispatch(setLang(data)),
    signout: () => dispatch(signout()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'mainLayout',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'mainLayout', saga: saga });

export default compose(withReducer, withSaga, withConnect, withRouter)(MainLayout);
