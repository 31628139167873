import gql from 'graphql-tag';

export default gql`
  query brands {
    brands {
      data {
        id
        name
        productCategories {
          id
          name
        }
        imageUrl
      }
    }
  }
`;
