/*
 * UserManagementPage Messages
 *
 * This contains all the text for the UserManagementPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UserManagementPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Quản lý người dùng',
  },
  inviteUser: {
    id: `${scope}.inviteUser`,
    defaultMessage: 'Mời đồng nghiệp',
  },
  invitationSent: {
    id: `${scope}.invitationSent`,
    defaultMessage:
      'Hãy nhắn đồng nghiệp của bạn kiểm tra hộp thư và làm theo hướng dẫn để hoàn thành cài đặt tài khoản nhé.',
  },
  congrat: {
    id: `${scope}.congrat`,
    defaultMessage: 'Lời mời đã được gửi !',
  },
  invite: {
    id: `${scope}.invite`,
    defaultMessage: 'Mời',
  },
  deleteUser: {
    id: `${scope}.deleteUser`,
    defaultMessage: 'Xoá tài khoản',
  },
  confirmToDeleteUser: {
    id: `${scope}.confirmToDeleteUser`,
    defaultMessage: 'Bạn chắc chắn muốn xoá tài khoản này chứ?',
  },
  inviteFailed: {
    id: `${scope}.inviteFailed`,
    defaultMessage: 'Mời đồng nghiệp thất bại',
  },
});
