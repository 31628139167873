import gql from 'graphql-tag';

export default gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      items {
        product {
          id
          nameLocal
          imageUrl
          uom
          uomLocal
        }
        total
        buyerNote
        deliveryQuantity
        orderedQuantity
        deliveredNote
        internalCode
        unitPriceAtOrderCreated {
          value
          vatIncluded
          vat
        }
      }
      orderDate
      deliveryTime {
        start
        end
      }
      acceptedAt
      acceptedBy {
        id
        name
        email
        telephone
      }
      acceptedNote
      declinedAt
      declinedBy {
        id
        name
        email
        telephone
      }
      declinedNote
      status
      paymentMethod
      buyer {
        id
        name
        telephone
        location {
          city
          district
          address
        }
        redInvoiceInfo {
          companyName
          location {
            city
            district
            address
          }
          taxCode
        }
      }
      store {
        id
        name
        telephone
        location {
          address
          district
          ward
          city
        }
      }
      creator {
        id
        name
        email
        telephone
      }
      buyerNote
      total
      redelivery
      orderType
    }
  }
`;
