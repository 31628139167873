import gql from 'graphql-tag';

export default gql`
  query invitation($invitationId: ID!) {
    invitation(invitationId: $invitationId) {
      id
      name
      imageUrl
      telephone
      status
      location {
        city
        address
        district
      }
      redInvoiceInfo {
        companyName
        location {
          city
          address
          district
        }
        taxCode
      }
      lastOrder
      paymentMethod
      stores {
        id
        name
        imageUrl
        telephone
        location {
          city
          address
          district
        }
      }
      settings {
        moq
        deliverySchedule {
          weekday
          start
          end
          timeWindow
        }
        fulfillmentTime {
          hoursToDelivery
          days
          cutoffTime
        }
        peopleInCharge {
          id
        }
      }
    }
  }
`;
