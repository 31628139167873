import * as Sentry from '@sentry/browser';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { push } from 'connected-react-router';
import { ImportOrderInput } from 'containers/OrdersPage';
import { print } from 'graphql';
import jwtDecode from 'jwt-decode';
import { call, put } from 'redux-saga/effects';
import fetch from 'unfetch';
import userRemove from 'utils/query/userRemove';
import { version } from '../../../package.json';
import { MutationCompanyUpdateRedInvoiceArgs, OrderUpdateDeliveryDateTimeInput } from '../../types/schema';
import { HO_CHI_MINH_TIMEZONE } from '../constants';
import {
  accountCreate,
  accountUpdate,
  buyer,
  buyerSettings,
  buyers,
  categories,
  checkEmail,
  clientAddAllSellingList,
  clientAddSellingList,
  clientDelete,
  clientDeleteSellingItem,
  clientDownloadSellingPDF,
  clientRemoveAllSellingList,
  clientSellingList,
  clientUpdate,
  companyUpdateRedInvoice,
  groups,
  invitation,
  invitationSlot,
  invitationUpdateCompany,
  invitationUpdatePaymentMethod,
  invitationUpdateStore,
  invitations,
  me,
  meForgetPassword,
  meLogin,
  meRelogin,
  meResetPassword,
  meUpdatePassword,
  order,
  orderAccept,
  orderActivity,
  orderDecline,
  orderImport,
  orderStatistic,
  orderUpdateDeliveryDateTime,
  orders,
  ordersExport,
  ordersPrint,
  ordersPrintAll,
  origins,
  preservations,
  product,
  productCreate,
  productDelete,
  productDeleteDiscountPrice,
  productDiscountPriceBuyers,
  productSetDiscountPrice,
  productSetStock,
  productUpdate,
  products,
  productsSpecial,
  setBuyerSettings,
  setPaymentMethod,
  setSettings,
  settings,
  shortBuyers,
  supplier,
  supplierUpdate,
  tags,
  uoms,
  userInvite,
  users,
  brands,
  getAllKamereoRegions,
  productHistoryDetail,
} from '../query';
import getRegion from '../../utils/getRegion';

const apiUrl = process.env.API_URL || 'https://supplier-graphql.qa.kamereo.vn';

const httpLink = new HttpLink({
  fetch: window.fetch || fetch,
  uri: apiUrl,
});

const authLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists

  let requestToken = localStorage.getItem('token');

  if (requestToken) {
    const decodedToken = jwtDecode(requestToken);

    if (Date.now() > decodedToken.exp * 1000) {
      const rememberToken = window.localStorage.getItem('rememberToken');
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({
          operationName: 'meRelogin',
          variables: { token: rememberToken },
          query: print(meRelogin),
        }),
        headers: {
          accept: '*/*',
          'content-type': 'application/json',
        },
      });
      const data = await response.json();
      const error = (data.errors || []).find(err =>
        (err.graphQLErrors || []).find((e: any) => {
          const { subcode } = e.extensions;
          return subcode === '6101' || subcode === '6102';
        }),
      );

      if (error) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('rememberToken');
      }

      if (data && data.data && data.data.meRelogin && data.data.meRelogin.token) {
        window.localStorage.setItem('token', data.data.meRelogin.token);
        if (data.data.meRelogin.rememberMeToken) {
          window.localStorage.setItem('rememberToken', data.data.meRelogin.rememberMeToken);
        }

        requestToken = data.data.meRelogin.token;
      }
    }
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: requestToken ? `Bearer ${requestToken}` : '',
      'Accept-Language': window.localStorage.getItem('lang'),
      'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone || HO_CHI_MINH_TIMEZONE,
      'X-Client-AppName': 'SupplierWeb',
      'X-Client-Version': version,
    },
  };
});

const defaultOptions: any = {
  watchQuery: { fetchPolicy: 'network-only', errorPolicy: 'ignore' },
  query: { fetchPolicy: 'network-only', errorPolicy: 'ignore' },
};

const client = new ApolloClient({
  defaultOptions,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const request = function* request(options: any, authentication: boolean = true) {
  const loggedIn = !!window.localStorage.getItem('token');

  if (authentication && !loggedIn) {
    yield put(push('/signin'));
    // eslint-disable-next-line prefer-promise-reject-errors
    return yield Promise.reject({ errors: [{ message: 'Authentication is required' }] });
  }

  function checkTypeName(variables) {
    if (!variables || typeof variables !== 'object') {
      return;
    }

    if ('__typename' in variables) {
      // eslint-disable-next-line no-console
      console.error('Request params has __typename, please delete it.', options);

      Sentry.withScope(scope => {
        scope.setExtra('variables', variables);
        scope.setExtra('options', options);
        Sentry.captureException('Request params has __typename, please delete it.');
      });
      /* eslint-disable-next-line no-underscore-dangle, no-param-reassign */
      delete variables.__typename;
    }

    Object.keys(variables).forEach((key: string) => {
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        checkTypeName(variables[key]);
      }
    });
  }

  if (options.variables && typeof options.variables === 'object') {
    checkTypeName(options.variables);
  }

  if (options.mutation) {
    return yield client
      .mutate({ ...options, errorPolicy: 'all' })
      .then((response: any) => {
        if (options.mutation.definitions.length === 1) {
          if (response.data) {
            if (options.transform) {
              return options.transform(response.data);
            }
            const data = response.data[options.mutation.definitions[0].name.value];
            if (data) {
              if (data.success) {
                return data;
              }
              return { errors: data.userErrors };
            }
          }
          return { errors: response.errors || [{ message: 'Something wrong' }] };
        }
        return options.transform ? options.transform(response.data) : response.data;
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'production') {
          Sentry.withScope(scope => {
            Object.keys(options).forEach(key => {
              scope.setExtra(key, options[key]);
            });

            Sentry.captureException(error);
          });
        }

        return {
          errors: [error],
        };
      });
  }
  return yield client
    .query(options)
    .then((response: any) => {
      if (response.errors) {
        return { errors: response.errors || [{ message: 'Something wrong' }] };
      }
      return response.data;
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'production') {
        Sentry.withScope(scope => {
          Object.keys(options).forEach(key => {
            scope.setExtra(key, options[key]);
          });

          Sentry.captureException(error);
        });
      }

      return {
        errors: [error],
      };
    });
};

export function* getOrder(variables: any) {
  return yield call(request, { variables, query: order });
}
export function* acceptOrder(variables: any) {
  return yield call(request, { variables, mutation: orderAccept }, false);
}
export function* declineOrder(variables: any) {
  return yield call(request, { variables, mutation: orderDecline }, false);
}
export function* updateDeliveryDateTime(variables: OrderUpdateDeliveryDateTimeInput) {
  return yield call(request, { variables: { input: variables }, mutation: orderUpdateDeliveryDateTime }, false);
}
export function* getOrders(variables: any) {
  const region = getRegion();
  return yield call(request, { variables: { ...variables, regionCodes: [region] }, query: orders });
}
export function* exportOrders(variables: any) {
  const region = getRegion();
  return yield call(request, { variables: { ...variables, regionCodes: [region] }, query: ordersExport });
}
export function* importOrder(variables: { input: ImportOrderInput }) {
  return yield call(request, { variables, mutation: orderImport });
}
export function* printOrders(variables: any) {
  return yield call(request, { variables, query: ordersPrint });
}
export function* printAllOrders(variables: any) {
  const region = getRegion();
  return yield call(request, { variables: { ...variables, regionCodes: [region] }, query: ordersPrintAll });
}
export function* getBuyers(variables: any) {
  const region = getRegion();
  const newVariables = {
    ...variables,
    filter: {
      ...variables.filter,
      regionCode: region,
    },
  };
  return yield call(request, { variables: newVariables, query: buyers });
}
export function* getBuyer(variables: any) {
  return yield call(request, { variables, query: buyer });
}
export function* getShortBuyers(variables: any) {
  return yield call(request, { variables, query: shortBuyers });
}
export function* getCategories() {
  return yield call(request, { query: categories });
}
export function* getUOMs() {
  return yield call(request, { query: uoms });
}
export function* getOrigins() {
  return yield call(request, { query: origins });
}
export function* getPreservations() {
  return yield call(request, { query: preservations });
}
export function* signin(variables: any) {
  return yield call(request, { variables, mutation: meLogin }, false);
}
export function* signup(variables: any) {
  return yield call(request, { variables, mutation: accountCreate }, false);
}
export function* checkValidEmail(variables: any) {
  return yield call(request, { variables, mutation: checkEmail }, false);
}
export function* forgetPassword(variables: any) {
  return yield call(request, { variables, mutation: meForgetPassword }, false);
}
export function* getOrderStatistic(variables: any) {
  const region = getRegion();
  return yield call(request, { variables: { ...variables, regionCodes: [region] }, query: orderStatistic });
}
export function* getOrderActivity(variables: any) {
  const region = getRegion();
  return yield call(request, { variables: { ...variables, regionCodes: [region] }, query: orderActivity });
}
export function* getProducts(variables: any) {
  return yield call(request, { variables, query: products });
}
export function* getSpecialProducts(variables: any) {
  return yield call(request, { variables, query: productsSpecial });
}
export function* getProduct(variables: any) {
  return yield call(request, { variables, query: product });
}
export function* createProduct(variables: any) {
  return yield call(request, { variables, mutation: productCreate });
}
export function* updateProduct(variables: any) {
  return yield call(request, { variables, mutation: productUpdate });
}
export function* setStockProduct(variables: any) {
  return yield call(request, { variables, mutation: productSetStock });
}
export function* deleteProduct(variables: any) {
  return yield call(request, { variables, mutation: productDelete });
}
export function* resetPassword(variables: any) {
  return yield call(request, { variables, mutation: meResetPassword }, false);
}
export function* getUser() {
  return yield call(request, { query: me });
}
export function* getGroups() {
  return yield call(request, { query: groups });
}
export function* updateProfile(variables: any) {
  return yield call(request, { variables, mutation: accountUpdate });
}
export function* updatePassword(variables: any) {
  return yield call(request, { variables, mutation: meUpdatePassword });
}
export function* getSpecialPrices(variables: any) {
  return yield call(request, { variables, query: productDiscountPriceBuyers });
}
export function* setSpecialPrice(variables: any) {
  return yield call(request, { variables, mutation: productSetDiscountPrice });
}
export function* deleteSpecialPrice(variables: any) {
  return yield call(request, { variables, mutation: productDeleteDiscountPrice });
}
export function* getUsers(variables: any) {
  return yield call(request, { variables, query: users });
}
export function* sendInviteUsers(variables: any) {
  return yield call(request, { variables, mutation: userInvite });
}
export function* deleteUser(variables: any) {
  return yield call(request, { variables, mutation: userRemove });
}
export function* getSupplier() {
  return yield call(request, { query: supplier });
}
export function* updateSupplier(variables: any) {
  return yield call(request, { variables, mutation: supplierUpdate });
}
export function* getSettings() {
  return yield call(request, { query: settings });
}
export function* updateSettings(variables: any) {
  return yield call(request, { variables, mutation: setSettings });
}
export function* getBuyerSettings(variables) {
  return yield call(request, { variables, query: buyerSettings });
}
export function* getInvitations(variables) {
  return yield call(request, { variables, query: invitations });
}
export function* updateBuyerSettings(variables: any) {
  return yield call(request, { variables, mutation: setBuyerSettings });
}
export function* updatePaymentMethod(variables: any) {
  return yield call(request, { variables, mutation: setPaymentMethod });
}
export function* updateClient(variables: any) {
  return yield call(request, { variables, mutation: clientUpdate });
}
export function* updateRedInvoice(variables: MutationCompanyUpdateRedInvoiceArgs) {
  return yield call(request, { variables, mutation: companyUpdateRedInvoice });
}
export function* updateInvitationPaymentMethod(variables: any) {
  return yield call(request, { variables, mutation: invitationUpdatePaymentMethod });
}
export function* updateInvitationCompany(variables: any) {
  return yield call(request, {
    variables,
    mutation: invitationUpdateCompany,
  });
}
export function* updateInvitationStore(variables: any) {
  return yield call(request, { variables, mutation: invitationUpdateStore });
}
export function* getInvitation(variables: any) {
  return yield call(request, { variables, query: invitation });
}
export function* addSellingList(variables: any) {
  return yield call(request, { variables, mutation: clientAddSellingList });
}
export function* removeSellingList(variables: any) {
  return yield call(request, { variables, mutation: clientDeleteSellingItem });
}
export function* invitationRemainSlot() {
  return yield call(request, { query: invitationSlot });
}
export function* deleteClient(variables: any) {
  return yield call(request, { variables, mutation: clientDelete });
}
export function* clientAddAllSellingItems(variables: any) {
  return yield call(request, { variables, mutation: clientAddAllSellingList });
}
export function* clientRemoveAllSellingItems(variables: any) {
  return yield call(request, { variables, mutation: clientRemoveAllSellingList });
}
export function* clientSelling(variables: any) {
  return yield call(request, { variables, query: clientSellingList });
}
export function* downloadSellingPDF(variables: any) {
  return yield call(request, { variables, mutation: clientDownloadSellingPDF });
}
export function* getTags() {
  return yield call(request, { query: tags });
}
export function* getBrands() {
  return yield call(request, { query: brands });
}

export function* fetchAllKamereoRegions() {
  return yield call(request, { query: getAllKamereoRegions });
}

export function* getProductHistoryChanges(variables: any) {
  return yield call(request, { variables, query: productHistoryDetail, fetchPolicy: 'no-cache' });
}
