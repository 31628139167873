import gql from 'graphql-tag';

export default gql`
  query buyers($sort: [BuyerSort], $filter: BuyerFilterInput, $pagination: Pagination) {
    buyers(sort: $sort, filter: $filter, pagination: $pagination) {
      total
      totalPage
      data {
        id
        name
        telephone
        location {
          city
          district
          ward
          address
        }
        imageUrl
        lastOrder
      }
    }
  }
`;
