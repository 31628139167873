import gql from 'graphql-tag';

export default gql`
  query invitations($filter: GetInvitationsInput) {
    invitations(filter: $filter) {
      data {
        id
        name
        stores {
          id
          name
          imageUrl
          location {
            address
            district
            city
          }
        }
        status
        imageUrl
      }
    }
  }
`;
