import gql from 'graphql-tag';

export default gql`
  mutation invitationCreate($name: String) {
    invitationCreate(name: $name) {
      userErrors {
        field
        message
      }
      success
      id
    }
  }
`;
