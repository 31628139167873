import gql from 'graphql-tag';

export default gql`
  mutation orderUpdateDeliveryDateTime($input: OrderUpdateDeliveryDateTimeInput!) {
    orderUpdateDeliveryDateTime(input: $input) {
      success
      order {
        id
        deliveryTime {
          start
          end
        }
      }
    }
  }
`;
