/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import 'moment/locale/vi';
import 'moment/locale/en-gb';
// import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.less';
import './global-styles.scss';
// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import * as React from 'react';
import * as ReactDOM from 'react-dom';
// Import all the third party stuff
import * as Sentry from '@sentry/browser';

// Import root app
import App from 'containers/App';
import { ConnectedRouter } from 'connected-react-router';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import history from 'utils/history';
import { message } from 'antd';
import moment from 'moment-timezone';
import qs from 'qs';
import release from 'config/release.json';
// Import i18n messages
import { translationMessages } from 'i18n';
import { HO_CHI_MINH_TIMEZONE } from './utils/constants';

const search = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
}) as { lang: string };

if (search.lang) {
  window.localStorage.setItem('lang', search.lang);
}

message.config({
  duration: 6,
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.APP_ENV || 'development',
    release: release.version || process.env.RELEASE || 'latest',
    dsn: 'https://9b1f1b584de1463783ab5b199c6ce31f@sentry.io/1440789',
  });
}

// Import i18n messages

moment.locale(window.localStorage.getItem('lang') === 'vi' ? 'vi' : 'en-gb');

moment.updateLocale('en-gb', {
  monthsShort: '01_02_03_04_05_06_07_08_09_10_11_12'.split('_'),
});
moment.tz.setDefault(HO_CHI_MINH_TIMEZONE);

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

// tslint:disable-next-line
const render = (messages, Component = App) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

declare const module: any;
if (module.hot) {
  module.hot.accept(['./i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    // tslint:disable-next-line
    const App = require('./containers/App').default; // https://github.com/webpack/webpack-dev-server/issues/100
    render(translationMessages, App);
  });
}
// Chunked polyfill for browsers without Intl support
if (!(window as any).Intl) {
  new Promise(resolve => resolve(import('intl')))
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/vi.js')])) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  const runtime = require('offline-plugin/runtime');
  runtime.install({
    autoUpdate: 1000 * 60 * 30,
    onUpdateReady: () => {
      runtime.applyUpdate();
    },
    onUpdated: () => {
      window.location.reload();
    },
    publicPath: '/',
  });
}
