/**
 *
 * Icon
 *
 */

import { Icon as AIcon } from 'antd';
import ICONS from './icons';
import { IconProps } from 'antd/lib/icon';
import React from 'react';

interface OwnProps extends IconProps {
  type: string;
  className?: string;
}

const Icon: React.SFC<OwnProps> = ({ type, ...props }: OwnProps) => {
  const IconContainer = ICONS[type];
  if (IconContainer) {
    return <AIcon {...props} component={IconContainer} />;
  }
  return <AIcon {...props} type={type} />;
};

export default Icon;
